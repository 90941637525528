import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BillList from './bill_list';
import BillDetail from './bill_detail';

class Bill extends Component {
  componentDidMount(){
  }
  render() {
    //console.log(this.context);
    var params = this.context.router.route.match.params;
    var view;
    if(params.action===undefined||params.action==='filter'){
      view = <BillList/>;
    }else{
      view = <BillDetail/>;
    }
    return (
      <div className="page-wrapper">{view}</div>
    );
  }
}

Bill.contextTypes = {
  router: PropTypes.object
};

export default Bill;
