import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {Typography,Fab,Toolbar,MenuItem,Divider,TextField,FormLabel} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import ReactTable from './shares/react_table';

import classNames from 'classnames';

import {styles} from '../layout/theme';
import Utils from '../api/api';


export class BillList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false,
      paymentStatusSelect: '',
      paymentStatus: [
        {title: 'Đặt hàng',value:0},
        // {title: 'Đang vận chuyển',value:1},
        // {title: 'Đã giao hàng - thanh toán cod',value:2},
        {title: 'Đã thanh toán',value:3},
        // {title: 'Đã giao hàng - thanh toán online/chuyển khoản',value:4},
        {title: 'Đã hoàn thành',value:5},
        {title: 'Đã hủy',value:6},
      ],
      tab: 0,
      filter: JSON.stringify({paymentStatus:0})
    };
  }
  componentDidMount() {
    // const $this = this;
    // this.setState({paymentStatusSelect:0},function(){
    //   $this.handleFilter();
    // });
  }
  handleSearch(e){
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd(){
    this.context.router.history.push('/bill/add');
  }
  handleFilter(){

    let f = this.state.filter;

    if(f==='') {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if(this.state.paymentStatusSelect!==''){
      f.paymentStatus = this.state.paymentStatusSelect;
    } else {
      if(f.paymentStatus!==undefined) {
        delete f.paymentStatus;
      }
    }

    //console.log(f);

    this.setState({filter:JSON.stringify(f)});
  }
  handleChangeTab = (event, value) => {

    var $this = this,
        v = $this.state.paymentStatus[value].value;

    this.setState({ tab: value, paymentStatusSelect: v},function(){
      $this.handleFilter();
    });
  };
  render(){
    const $this = this;
    const classes = this.props.classes;
    const value = this.state.tab;

    var source = {
      thead: [{title:'Số HĐ',value:'no'},{ title: 'Ngày', value: 'date' },{ title: 'Khách', value: 'customer' },   { title: 'Trị giá', value: 'total' },{ title: 'Hình thức thanh toán', value: 'payment' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          no: n.invoiceNo,
          date: Utils.formatFullDate(n.date),
          customer: n?.user?.email,
          total: n.amount,
          payment: n.paymentType,
        })
      }
    };

    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Hóa đơn</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <AppBar position="static" className={classes.subToolbar}>
            <Tabs value={value} onChange={this.handleChangeTab} variant="scrollable" scrollButtons="auto">
              <Tab label={$this.state.paymentStatus[0].title} />
              <Tab label={$this.state.paymentStatus[1].title} />
              <Tab label={$this.state.paymentStatus[2].title} />
              <Tab label={$this.state.paymentStatus[3].title} />
            </Tabs>
          </AppBar>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value})}
              margin="normal"/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='bills' apiSingle='bill' apiTable='bill' apiFilter={this.state.filter} router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

BillList.propTypes = {
  classes: PropTypes.object.isRequired,
};
BillList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(BillList);
