import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {FormGroup,TextField, Typography, Button, FormControl, Toolbar, Chip} from '@material-ui/core';
import language from '../layout/language';

import Utils from '../api/api';
import theme, {styles} from '../layout/theme';

import WebConfig from '../api/config';

var config = WebConfig.production;
if(window.location.hostname.indexOf('local')!==-1){
  config = WebConfig.development;
}

class BillDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bill: {
        invoiceNo:'',
        getflyId: '',
        amount:'',
        bag:{
          products:[],
          courses:[],
          services: []
        },
        date: new Date().toString(),
        dateComplete:'',
        note:'',
        paymentStatus:'',
        paymentType:'',
        user:{},
        deliveryTo: {
          address:'',
          city:'',
          cityText:'',
          district:'',
          districtText:'',
          fullname:'',
          phone:'',
          ward:'',
          wardText:''
        }
      },
      isUpdate: false,
      paymentType:[
        {title: 'Tiền mặt', value: 'direct'},
        {title: 'Chuyển khoản', value: 'transfer'},
      ],
      paymentStatus: [
        {title: 'Đặt hàng',value:0},
        // {title: 'Đang vận chuyển',value:1},
        // {title: 'Đã giao hàng - thanh toán cod',value:2},
        {title: 'Đã thanh toán',value:3},
        // {title: 'Đã giao hàng - thanh toán online/chuyển khoản',value:4},
        {title: 'Đã hoàn thành',value:5},
        {title: 'Đã hủy',value:6},
      ]
    };
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('bill',params.id, function(res) {
          if (res.status == 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                //console.log(item[property]);
                data[property] = item[property];
              }
            }
            data._id = item._id;
            $this.setState({bill: data},function(){
              //console.log($this.state.banner);
            });
          }
        });
      });
    }
  }
  handleFieldChange = (e) => {
    var $this = this,
        bill = this.state.bill;
    bill[e.target.id] = e.target.value;
    this.setState({bill:bill});
  }
  _save(){
    var $this = this;
    var data = this.state.bill;
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('bill',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/bill/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true});
    }
    var $this = this;
    var obj = this.state.error;
    this._save();
  }
  handleCancel() {
    this.context.router.history.push('/bill/');
  }
  render() {
    const classes = this.props.classes;
    const bill = this.state.bill;
    const locale = 'VN';

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Cập nhật hóa đơn</Typography>
        </Toolbar>
        <form className={classes.form} noValidate>
          <TextField label="No." className={classes.textField} value={bill.invoiceNo} disabled={true} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col3}>
              <TextField label="Ngày tạo" id="date" className={classes.textField + ' form-control-no-bottom'} value={Utils.formatDate(bill.date)} type="date" onChange={this.handleFieldChange} margin="normal" InputLabelProps={{shrink: true}}/>
            </FormControl>
            <FormControl className={classes.col3}>
              <TextField label="Trị giá" className={classes.textField + ' form-control-no-bottom'} value={bill.amount} disabled={true} margin="normal" InputLabelProps={{shrink: true}}/>
            </FormControl>
            <FormControl className={classes.col3}>
              {/* <TextField label="Phương thức thanh toán" className={classes.textField} value={bill.paymentType} margin="normal" InputLabelProps={{shrink: true}}/> */}
              <Typography className={classes.label} type="caption">Phương thức thanh toán</Typography>
              <select id="paymentType" className={'select ' + classes.select} value={bill.paymentType} onChange={this.handleFieldChange}>
                {this.state.paymentType.map((item, index) => (
                  <option key={index} value={item.value}>{item.title}</option>
                ))}
              </select>
            </FormControl>
            <FormControl className={classes.col3}>
              <Typography className={classes.label} type="caption">Tình trạng đơn hàng</Typography>
              <select id="paymentStatus" className={'select ' + classes.select} value={bill.paymentStatus} onChange={this.handleFieldChange}>
                {this.state.paymentStatus.map((item, index) => (
                  <option key={index} value={item.value}>{item.title}</option>
                ))}
              </select>
            </FormControl>
          </FormGroup>
          <TextField multiline={true} rows={5} label="Ghi chú của khách hàng" className={classes.textField} value={bill.note} disabled={true} margin="normal" InputLabelProps={{
            shrink: true
          }}/>
          <Table className={classes.tableResponsive}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>STT</TableCell>
                <TableCell>Sản phẩm</TableCell>
                <TableCell>Giá</TableCell>
                <TableCell>Số lượng</TableCell>
                <TableCell>Trị giá</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bill.bag.products.map((n,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>
                    <h3>{n.nameVN}</h3>
                    {
                      n.variant!==undefined&&n.variant!==''?
                      (
                        <p>
                        {
                          Object.keys(n.variant.values).map((y,i)=>{
                            if(y!=='status'&&y!=='price'){
                              return (i>0?', ':'')  + n.variant.values[y];
                            }
                          })
                        }
                        </p>
                      ):''
                    }
                    </TableCell>
                    <TableCell>
                    {
                      n.variant!==undefined?(
                        <span className="price">{Utils.formatCurrency(parseFloat(n.variant.values.price),locale)}</span>
                      ):(
                        <span className="price">{Utils.formatCurrency(parseFloat(n['priceSale']),locale)}</span>
                      )
                    }
                    </TableCell>
                    <TableCell>{n.total}</TableCell>
                    <TableCell>
                    {
                      n.variant!==undefined?(
                        <span className="price">{Utils.formatCurrency(parseFloat(n.total*n.variant.values.price),locale)}</span>
                      ):(
                        <span className="price">{Utils.formatCurrency(parseFloat(n.total*n.priceSale),locale)}</span>
                      )
                    }
                    </TableCell>
                  </TableRow>
                );
              })}
              {bill.bag.courses.map((n,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{bill.bag.products.length+index+1}</TableCell>
                    <TableCell>{n.classnameVN}</TableCell>
                    <TableCell>{n.priceSale}</TableCell>
                    <TableCell>{n.total}</TableCell>
                    <TableCell>{n.priceSale*n.total}</TableCell>
                  </TableRow>
                );
              })}
              {bill.bag.services.map((n,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{bill.bag.products.length+bill.bag.courses.length+index+1}</TableCell>
                    <TableCell>{n.classnameVN}</TableCell>
                    <TableCell>{n.priceSale}</TableCell>
                    <TableCell>{n.total}</TableCell>
                    <TableCell>{n.priceSale*n.total}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <FormGroup row className={classes.formRow}>
            <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
            <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event)}>{language.VN.btnSave}</Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

BillDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
BillDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(BillDetail);
